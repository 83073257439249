import React from 'react';
import { Routes, Route, Outlet, Navigate } from "react-router-dom";
import { useSelector } from 'react-redux';

import LoginPage from './login.js'
import MainPage from './MainPage.js'


const Service = () => {
  return (
    <div>
      ServicePage
    </div>
  )
}

const RoutesMain = () => {
  const loggedIn = useSelector(state => state.user.loggedIn)
  console.log("LoggedIn", loggedIn)

  return (
    <Routes>
      <Route path="/" element={<LoginPage />} />
      <Route element={<ProtectedRoute user={loggedIn} />}>
        <Route path="mainpage/*" element={<MainPage />} />
      </Route>
    </Routes>
  );
}

const ProtectedRoute = ({
  user,
  redirectPath = '/',
}) => {
  if (user === false) {
    return <Navigate to={redirectPath} replace />;
  }
  return <Outlet />;
};

export default RoutesMain;
