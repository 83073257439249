import React, { memo } from 'react'

import styles from './tableIO.module.css';

const Row = ({ machineId, engine, serialnr, type }) => {
  return (
    <tr>
      <td>{machineId}</td>
      <td>{engine}</td>
      <td>{serialnr}</td>
      <td>{type}</td>
    </tr>
  )
}

const Table = ({ data }) => (
  <tbody>
    { data?.length > 0 &&
      data.map(({ machineId, engine, serialnr, type }) => (
        <Row key={machineId} machineId={machineId} engine={engine} serialnr={serialnr} type={type} />
      ))
    }
  </tbody>
)

const TableIoDef = ({ dataIO }) => {
  return (
    <>
      <div className={styles.mainTableDef}>
        <table className={styles.tableIO_main}>
          <thead>
            <tr>
              <th>MachineId</th>
              <th>Engine</th>
              <th>Serialnr</th>
              <th>type</th>
            </tr>
          </thead>
            <Table data={dataIO} />
        </table >
      </div>
    </>
  );
}

export const TableIODef = memo(TableIoDef)
