import React, { memo } from 'react'

import styles from './AllserviceTable.module.css';

const Row = ({ ServiceId, ServiceDealer, ServicePerformedDate }) => {
  return (
    <tr>
      <td>{ServiceId}</td>
      <td>{ServiceDealer}</td>
      <td>{ServicePerformedDate}</td>
    </tr>
  )
}

const Table = ({ data }) => (
  <tbody>
    {data?.length > 0 &&
      data.map(({ ServiceId, ServiceDealer, ServicePerformedDate}) => (
        <Row key={ServiceId} ServiceId={ServiceId} ServiceDealer={ServiceDealer} ServicePerformedDate={ServicePerformedDate} />
      ))
    }
  </tbody>
)

const TableIoDef = ({ dataIO }) => {
  return (
    <>
      <div className={styles.mainTableDef}>
        <table className={styles.tableIO_main}>
          <thead>
            <tr>
              <th>Id</th>
              <th>Dealer</th>
              <th>PerformedDate</th>

              {/*
              <th>Comment</th>
              
              
              <th>IntervalHour</th>
              <th>MachienId</th>
              <th>MachineHours</th>
              <th>Performed</th>
              
              <th>PersName</th>
              <th>TechnicanId</th>
              <th>VP</th>
              */}
            </tr>
          </thead>
          <Table data={dataIO} />
        </table >
      </div>
    </>
  );
}

export const AllserviceTable = memo(TableIoDef)
