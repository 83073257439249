import React, { useEffect, useState } from 'react'
import { useForm } from "react-hook-form";
import { useDispatch } from "react-redux";
import axios from 'axios';
import { useNavigate } from "react-router-dom";
import {login, logout, loggedIn, setUser} from './features/user/userSlice.js'
import FLEETAPI from './connections/api';

// import { newUser } from '../redux/therapistSlice';

//import { tokenRequest, requestToken } from '../redux/user';

//import crasA from '../svg/crasAlkohol.svg';
//import insigtBlue from '../images/CRAS_Insight_logo_blue.png';
//import newLogo from '../images/Logo_eSante_3_2.png';
import ecolog from './EcoLogIcon-white-background.png'
import styles from './login.module.css';

//import Button from './components/Button';
//let token = "eyJhbGciOiJIUzI1NiJ9.Mjg.BKdrOL1K50n3cp1JjbzyTmu4rxD8QqMaK8cmsQvijWM" 

//axios.defaults.headers.common['Authorization'] = `Bearer ${token}`;
axios.defaults.headers.post['Content-Type'] = 'application/json';


function LoginPage() {
  const { register, handleSubmit, formState: { errors } } = useForm();
  let navigate = useNavigate();
  const dispatch = useDispatch();

   const onSubmit = async (data) => {
    try {
        //const result = await axios.post('https://ecologportal.duckdns.org/fleetapi/user/login', data);
        //const result = await axios.post('http://localhost:3000/user/login', data);
        const result = await FLEETAPI.post('user/login', data)

        if (result.data.accessToken) {
          console.log('Inlogad gå vidare..');
          localStorage.setItem('token', result.data.accessToken);
          FLEETAPI.defaults.headers.common['Authorization'] = `Bearer ${result.data.accessToken}`;
          localStorage.setItem('loggedin', true);
          console.log(result);
          //setStatus('')
          dispatch(loggedIn(true))
          //dispatch(login(true))
          //const self = await axios.post('https://ecologportal.duckdns.org/fleetapi/user/self');
          //const self = await axios.post('http://localhost:3000/user/self');
          const self = await FLEETAPI.post('user/self')

          let suy = self.data.pop();
        
          console.log(suy)
          dispatch(setUser(suy))

          navigate("/mainpage" )
        } else {
          console.log('SATAN')
          console.log(result.data)
          //setStatus(result.data)  
        }
        
    } catch(err) {
        console.log(err)
    }
  }



 
  
  //const history = useHistory();
    /*
  const code = useSelector(({ user: { code }}) => code);
  //const loggedIn = useSelector(({ user: { loggedIn }}) => loggedIn);

  const onError = (err) => {
    // TODO: Handle errors
    console.log('Nu har det blivit fel: ' + err);

    alert('Fel användarnamn / lösenord');
  }
  
  console.log(watch("username")); // watch input value by passing the name of it

 

  useEffect(() => {
    if (code === null) return;

    dispatch(requestToken(code))
      .then(console.log)
      .catch(onError);
  }, [code, dispatch]);
*/

useEffect(() => {
  const fetchData = async () => {
    try {
      /*
      const result = await axios.post(
        'http://localhost:3000/users/login', 
          {
            name: "oscar.selin",
            password: "os"
          }
      );
      console.log(result.data);        
      */
      //const result = await axios.get('https//localhost:3000/solsidan')
      //console.log(result.data);

    } catch(err) {
      console.log(err)
    }
  };

    //fetchData();
  }, []);
  
  return (
    <div className={styles.container}>
      <div className={styles.box}>
        <div className={styles.form}>
          {/*<div>
            <img src={insigtBlue} alt="logo" width="200" />
          </div>
          */}
          <div style={{width: 160}}>
            <img src={ecolog} alt="logo" />
          </div>
          {/*<h3 className={styles.loginTxt}>Logga in med användarnamn</h3>*/}

          <form className={styles.forminput} onSubmit={handleSubmit(onSubmit)}>
            <input type="text" placeholder="Användarnamn" {...register("name", { required: true, maxLength: 20 })} />
              {errors.username && errors.username.type === "required" && <span>Du måste fylla i detta fällt</span>}
              {errors.username && errors.username.type === "maxLength" && <span>Användarnamnet är för långt!</span>}
            <input type="password" placeholder="Lösenord" {...register("password", { required: true, maxLength: 10 })} />
              {errors.password && errors.password.type === "required" && <span>Du måste fylla i ett lösenord</span>}
              {errors.password && errors.password.type === "maxLength" && <span>Lösenordet är för långt!</span>}
            <button className="buttonLogIn button2" type="submit">Logga in i EcoLog Fleet</button>
          </form>
          <p className={styles.txt}>
            EcoLog är godkända skogsmaskiner produkter med CE-märkning.
            All data överförs krypterat och behandlas enligt personuppgiftslagen, och EU:s dataskyddsförordning (GDPR). 
          </p>

          <div className={styles.txt_link}>
            <a href="https://ecologforestry.com/integritetspolicy" rel="noreferrer" target="_blank">Läs mer om vår datahantering</a>
            <a style={{paddingLeft: 30}} href="https://ecologforestry.com/se" rel="noreferrer" target="_blank">Läs mer om EcoLog</a> 
          </div>
        </div>
      </div>
      {/* <p style={{ color: 'white'}}>{status}</p> */}
    </div>
  )
}

export default LoginPage

